<template>
  <div class="container"></div>
</template>

<script>
import { getCodeAPI, refreshToken } from '@/api/index';
import website from '@/config/website';
import { Toast } from 'vant';
import dateUtil from "@/api/dateUtil.js";
export default {
  data() {
    return {
      headPortrait: '',
      petName: '',
      tenantId:'',
      code: '',
    };
  },
  created() {
    let url = window.parent.location.href
    console.log(window.parent.location.href, "url");
    let urlStr = url.split('?')[1]
	  const urlSearchParams = new URLSearchParams(urlStr);
	  const result = Object.fromEntries(urlSearchParams.entries());
    console.log(result,"result");
    this.tenantId = result.tenantId;
    console.log(this.tenantId, "tenantId......");
    const obj = {};
    obj.time = 86400000; // 过期时间(一天)
    obj.date = new Date().getTime();
    obj.dateTime = dateUtil.formatDate2(new Date());
    const objString = JSON.stringify(obj); // 由于 localStorage 只能保存字符串内容，所以这里要先把对象转换成 JSON 字符串
    window.localStorage.setItem("data", objString);
    const token = localStorage.getItem('token');
    const tenantId = localStorage.getItem('tenantId');
    console.log('auth created:', token);
    if (token === '' || token === null || token === 'undefined' || tenantId == null) {
      this.getCodes();
    } else {
      this.getToken();
      // window.setInterval(this.getToken(), 30 * 60 * 1000);
      setInterval(() => {
        this.getToken();
      }, 30 * 60 * 1000);
      this.$router.push({ path: '/menu' });
    }
  },

  methods: {
    // 获取code
    getCodes() {
      getCodeAPI(website.corp_id, this.tenantId)
        .then((res) => {
          window.location.href = res.data.data;
          console.log(res.data.data);
        })
        .catch((error) => {
          Toast(error.message);
          console.log(error, 'erro');
        });
    },
    getToken() {
      refreshToken(
        localStorage.getItem('tenantId'),
        localStorage.getItem('refresh_token'),
      )
        .then((res) => {
          const obj = {}
          obj.date = new Date().getTime()
          obj.dateTime = dateUtil.formatDate2(new Date())
          const objString = JSON.stringify(obj); // 由于 localStorage 只能保存字符串内容，所以这里要先把对象转换成 JSON 字符串
          localStorage.setItem('data', objString)
          // localStorage.setItem('token', res.data.access_token)
          localStorage.setItem('refresh_token', res.data.refresh_token)
        })
        .catch((erro) => {
          console.log(erro);
        });
    },
  },
};
</script>

<style scoped>
.container {
  width: 95%;
}
</style>
